"use client";

import { Occasion as OccasionType } from "../../../__generated__/crepo-types";
import { createLinkForCartoonsHomepage } from "../../../helpers/createLinkForCartoonsHomepage";
import { CosmosButton } from "@cosmos/web/react";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import { useLocaleContext } from "../../../helpers/LocaleContext";
import classnames from "classnames/bind";
import styles from "./occasion.module.css";
import { notNull } from "../../../helpers/notNull";
import { isImage } from "../../../helpers/typeguards";

const cx = classnames.bind(styles);

export const Occasion = ({
  card,
  buttonText,
  tabIndex,
}: {
  card: OccasionType;
  buttonText: string | null;
  tabIndex: number;
}) => {
  const locale = useLocaleContext();

  const imageLoading = useImageLoading();

  const image = card.featuredMedia?.filter(notNull).find(isImage);

  const mediaUrl =
    image?.imageEssence?.__typename === "CloudinaryImage"
      ? image.imageEssence.imageURL
      : image?.imageSrc;

  const resource = mediaUrl;

  return (
    <div className={cx("container")}>
      {resource && (
        <img
          className={cx("image")}
          srcSet={responsiveImageHelper.createCrepoSrcSet(resource, locale)}
          sizes="414px" // Manually synced with width of `card-stack__card-container`
          loading={imageLoading}
          alt=""
        />
      )}
      <div className={cx("content")}>
        <div className={cx("title-wrapper-wrapper")}>
          <div className={cx("title-wrapper")}>
            <h2 className={cx("title")}>
              {card.title ? card.title.text : "No headline available"}
            </h2>
          </div>
        </div>
        <div className={cx("bottom-content")}>
          {card.teaser && (
            <p className={cx("description")}>
              <span className={cx("description-text")}>{card.teaser.text}</span>
            </p>
          )}
          {/* todo: tabIndex currently isn't passed down to Web Components.
              Consider refractoring with `decorative` to disable the button */}
          <CosmosButton
            href={createLinkForCartoonsHomepage(locale)}
            size="large"
            kind="primary"
            className={cx("button")}
            tabIndex={tabIndex}
            width="full"
          >
            {buttonText}
          </CosmosButton>
        </div>
      </div>
    </div>
  );
};
