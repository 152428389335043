"use client";

import { useLayoutEffect, useRef } from "react";
import { useTextDirectionContext } from "../../../helpers/TextDirectionContext";
import { CardStack } from "../../card-stack/card-stack";
import { BlocksOccasionStack } from "../../../__generated__/contentful-types";
import classNames from "classnames/bind";
import {
  ExplodedWordsToSpans,
  waitForScrollConfig,
} from "../interactive-hero/utils/interactive-hero-utils";
import { useCardStackState } from "../../../helpers/useCardStackState";
import { Occasion as OccasionType } from "../../../__generated__/crepo-types";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap";
import styles from "./occasion-stack.module.css";
import { Occasion } from "../../card-stack/occasion/occasion";

export interface OccasionStackProps {
  block: BlocksOccasionStack;
  dotcomContent: OccasionType[];
  animated?: boolean;
}

const cx = classNames.bind(styles);

export const OccasionStack = ({
  block,
  dotcomContent,
  animated,
}: OccasionStackProps) => {
  const textDirection = useTextDirectionContext();
  const { cardStackState, cardStackActions } = useCardStackState({
    rtl: textDirection === "rtl",
  });
  const rootRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const descriptionRef = useRef<HTMLParagraphElement | null>(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (!animated) {
      return;
    }

    const ctx = gsap.context(() => {
      const rootElement = rootRef.current;
      const titleWordsElements = titleRef.current;
      const descriptionElement = descriptionRef.current;
      const cardsElement = rootElement?.querySelector(
        "[data-card-stack=container]",
      );
      const timeline = gsap.timeline();

      timeline.from(titleWordsElements ?? null, {
        duration: 0.4,
        y: "+=60%",
        opacity: 0,
        stagger: 0.1,
      });
      timeline.from(
        [descriptionElement, cardsElement],
        { duration: 0.4, y: "+=50", opacity: 0, stagger: 0.3 },
        "-=0.3",
      );
      timeline.pause();

      const scrollTrigger = new ScrollTrigger({
        trigger: rootElement,
        start: "top 70%",
        animation: timeline,
        once: true,
      });

      scrollTrigger.disable();

      waitForScrollConfig().then(() => {
        scrollTrigger.enable();
      });
    });

    return () => ctx.revert();
  }, [animated]);

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <div className={cx("content")}>
          <h2 ref={titleRef} className={cx("title")}>
            {animated ? (
              <ExplodedWordsToSpans text={block.title} />
            ) : (
              block.title
            )}
          </h2>
          {block.description && (
            <p ref={descriptionRef} className={cx("description")}>
              {block.description}
            </p>
          )}
          {dotcomContent && (
            <CardStack
              cards={dotcomContent}
              cardStackState={cardStackState}
              cardStackActions={cardStackActions}
              hasNavigationDots
              renderCard={({ card, index }) => (
                <Occasion
                  card={card}
                  buttonText={block.buttonText}
                  tabIndex={index === cardStackState.topCardIndex ? 0 : -1}
                />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};
